export const translations = {
  en: {
    loading_steps: {
      understanding: "Analyzing the request...",
      generating: "Generating the response...",
      verifying: "Final check...",
    },
    user: "You",
    assistant: "Super Adam",
    agree: "I Agree",
    disagree: "I Disagree",
    agreed: "You have agreed.",
    disagreed: "You have not agreed.",
    welcome: "Hello, I am Super Adam the AI assistant of Adam Matériaux!",
    askQuestions:
      "I am here to answer all your questions about Adam Matériaux and to assist you in navigating our site and recommand you products.",
    discover: "Ask me your questions...",
    search: "Searching in my sources",
  },
  de: {
    loading_steps: {
      understanding: "Analyse der Anfrage...",
      generating: "Generierung der Antwort...",
      verifying: "Letzte Überprüfung...",
    },
    user: "Sie",
    assistant: "Super Adam",
    agree: "Ich stimme zu",
    disagree: "Ich stimme nicht zu",
    agreed: "Sie haben zugestimmt.",
    disagreed: "Sie haben nicht zugestimmt.",
    welcome: "Hallo, ich bin der KI-Assistent von Adam Matériaux!",
    askQuestions:
      "Ich bin hier, um alle Ihre Fragen zu Adam Matériaux zu beantworten und Ihnen bei der Navigation auf unserer Website zu helfen.",
    discover: "Stellen Sie mir Ihre Fragen...",
    search: "Ich suche in meinen Quellen",
  },
  nl: {
    loading_steps: {
      understanding: "Analyseren van de aanvraag...",
      generating: "Genereren van het antwoord...",
      verifying: "Laatste controle...",
    },
    user: "U",
    assistant: "Super Adam",
    agree: "Ik ga akkoord",
    disagree: "Ik ga niet akkoord",
    agreed: "U bent akkoord gegaan.",
    disagreed: "U bent niet akkoord gegaan.",
    welcome: "Hallo, ik ben de AI-assistent van Adam Matériaux!",
    askQuestions:
      "Ik ben hier om al uw vragen over Adam Matériaux te beantwoorden en om u te helpen bij het navigeren op onze site.",
    discover: "Stel mij uw vragen...",
    search: "Aan het zoeken in mijn bronnen",
  },
  fr: {
    loading_steps: {
      understanding: "Analyse de la demande...",
      generating: "Génération de la réponse...",
      verifying: "Dernière vérification...",
    },
    user: "Vous",
    assistant: "Super Adam",
    agree: "Je suis d'accord",
    disagree: "Je ne suis pas d'accord",
    agreed: "Vous avez accepté.",
    disagreed: "Vous n'avez pas accepté.",
    welcome: "Bonjour, je suis Super Adam, l'assistant IA de Adam Matériaux !",
    askQuestions:
      "Je suis là pour répondre à toutes vos questions à propos de Adam Matériaux et pour vous assister dans votre navigation sur notre site et vous recommander des articles.",
    discover: "Posez-moi vos questions ...",
    search: "Je cherche dans mes sources",
  },
  es: {
    loading_steps: {
      understanding: "Analizando la solicitud...",
      generating: "Generando la respuesta...",
      verifying: "Verificación final...",
    },
    user: "Usted",
    assistant: "Super Adam",
    agree: "Estoy de acuerdo",
    disagree: "No estoy de acuerdo",
    agreed: "Ha aceptado.",
    disagreed: "No ha aceptado.",
    welcome: "Hola, soy Super Adam, el asistente de IA de Adam Matériaux!",
    askQuestions:
      "Estoy aquí para responder todas sus preguntas sobre Adam Matériaux y ayudarlo a navegar en nuestro sitio y recomendarle productos.",
    discover: "Haga sus preguntas...",
    search: "Buscando en mis fuentes",
  },
  sq: {
    loading_steps: {
      understanding: "Duke analizuar kërkesën...",
      generating: "Duke gjeneruar përgjigjen...",
      verifying: "Kontrolli final...",
    },
    user: "Ju",
    assistant: "Super Adam",
    agree: "Pajtohem",
    disagree: "Nuk pajtohem",
    agreed: "Ju keni pajtuar.",
    disagreed: "Ju nuk keni pajtuar.",
    welcome: "Përshëndetje, unë jam Super Adam, asistenti i AI i Adam Matériaux!",
    askQuestions:
      "Unë jam këtu për të përgjigjur të gjitha pyetjeve tuaja rreth Adam Matériaux dhe për t'ju ndihmuar të navigoni në faqen tonë.",
    discover: "Bëni pyetjet tuaja...",
    search: "Duke kërkuar në burimet e mia",
  },
  bg: {
    loading_steps: {
      understanding: "Анализиране на заявката...",
      generating: "Генериране на отговор...",
      verifying: "Финална проверка...",
    },
    user: "Вие",
    assistant: "Супер Адам",
    agree: "Съгласен съм",
    disagree: "Не съм съгласен",
    agreed: "Вие сте съгласен.",
    disagreed: "Вие не сте съгласен.",
    welcome: "Здравейте, аз съм Супер Адам, AI асистентът на Adam Matériaux!",
    askQuestions:
      "Аз съм тук, за да отговоря на всички ваши въпроси относно Adam Matériaux и да ви помогна да се ориентирате в нашия сайт.",
    discover: "Задайте въпросите си...",
    search: "Търсене в моите източници",
  },
  hr: {
    loading_steps: {
      understanding: "Analiziram zahtjev...",
      generating: "Generiram odgovor...",
      verifying: "Završna provjera...",
    },
    user: "Vi",
    assistant: "Super Adam",
    agree: "Slažem se",
    disagree: "Ne slažem se",
    agreed: "Složili ste se.",
    disagreed: "Niste se složili.",
    welcome: "Pozdrav, ja sam Super Adam, AI pomoćnik Adam Matériaux!",
    askQuestions:
      "Ovdje sam da odgovorim na sva vaša pitanja o Adam Matériaux i pomognem vam u navigaciji na našoj stranici.",
    discover: "Postavite svoja pitanja...",
    search: "Pretražujem svoje izvore",
  },
  el: {
    loading_steps: {
      understanding: "Ανάλυση του αιτήματος...",
      generating: "Δημιουργία απάντησης...",
      verifying: "Τελικός έλεγχος...",
    },
    user: "Εσείς",
    assistant: "Super Adam",
    agree: "Συμφωνώ",
    disagree: "Διαφωνώ",
    agreed: "Έχετε συμφωνήσει.",
    disagreed: "Δεν έχετε συμφωνήσει.",
    welcome: "Γεια σας, είμαι ο Super Adam, ο AI βοηθός της Adam Matériaux!",
    askQuestions:
      "Είμαι εδώ για να απαντήσω σε όλες τις ερωτήσεις σας για την Adam Matériaux και να σας βοηθήσω να περιηγηθείτε στον ιστότοπό μας.",
    discover: "Κάντε τις ερωτήσεις σας...",
    search: "Αναζήτηση στις πηγές μου",
  },
  hu: {
    loading_steps: {
      understanding: "Kérelem elemzése...",
      generating: "Válasz előállítása...",
      verifying: "Utolsó ellenőrzés...",
    },
    user: "Ön",
    assistant: "Super Adam",
    agree: "Egyetértek",
    disagree: "Nem értek egyet",
    agreed: "Ön egyetértett.",
    disagreed: "Ön nem értett egyet.",
    welcome: "Üdvözöllek, én vagyok Super Adam, az Adam Matériaux AI-asszisztense!",
    askQuestions:
      "Itt vagyok, hogy válaszoljak minden kérdésére az Adam Matériaux-val kapcsolatban és segítsek önnek az oldalunk böngészésében.",
    discover: "Tegye fel kérdéseit...",
    search: "Keresés a forrásaimban",
  },
  it: {
    loading_steps: {
      understanding: "Analizzando la richiesta...",
      generating: "Generando la risposta...",
      verifying: "Controllo finale...",
    },
    user: "Lei",
    assistant: "Super Adam",
    agree: "Sono d'accordo",
    disagree: "Non sono d'accordo",
    agreed: "Lei ha accettato.",
    disagreed: "Lei non ha accettato.",
    welcome: "Ciao, sono Super Adam, l'assistente AI di Adam Matériaux!",
    askQuestions:
      "Sono qui per rispondere a tutte le sue domande su Adam Matériaux e per aiutarla a navigare nel nostro sito.",
    discover: "Faccia le sue domande...",
    search: "Ricerca nelle mie fonti",
  },
  pl: {
    loading_steps: {
      understanding: "Analizowanie zapytania...",
      generating: "Generowanie odpowiedzi...",
      verifying: "Ostatnie sprawdzenie...",
    },
    user: "Ty",
    assistant: "Super Adam",
    agree: "Zgadzam się",
    disagree: "Nie zgadzam się",
    agreed: "Zgodziłeś się.",
    disagreed: "Nie zgodziłeś się.",
    welcome: "Witaj, jestem Super Adam, asystentem AI firmy Adam Matériaux!",
    askQuestions:
      "Jestem tutaj, aby odpowiedzieć na wszystkie Twoje pytania dotyczące Adam Matériaux i pomóc Ci nawigować po naszej stronie.",
    discover: "Zadaj swoje pytania...",
    search: "Szukam w moich źródłach",
  },
  pt: {
    loading_steps: {
      understanding: "Analisando a solicitação...",
      generating: "Gerando a resposta...",
      verifying: "Verificação final...",
    },
    user: "Você",
    assistant: "Super Adam",
    agree: "Concordo",
    disagree: "Discordo",
    agreed: "Você concordou.",
    disagreed: "Você não concordou.",
    welcome: "Olá, sou o Super Adam, assistente de IA da Adam Matériaux!",
    askQuestions:
      "Estou aqui para responder todas as suas perguntas sobre a Adam Matériaux e ajudá-lo a navegar em nosso site.",
    discover: "Faça suas perguntas...",
    search: "Pesquisando em minhas fontes",
  },
  ro: {
    loading_steps: {
      understanding: "Analizând cererea...",
      generating: "Generând răspunsul...",
      verifying: "Verificare finală...",
    },
    user: "Dumneavoastră",
    assistant: "Super Adam",
    agree: "Sunt de acord",
    disagree: "Nu sunt de acord",
    agreed: "Ați fost de acord.",
    disagreed: "Nu ați fost de acord.",
    welcome: "Bună, sunt Super Adam, asistentul AI de la Adam Matériaux!",
    askQuestions:
      "Sunt aici pentru a răspunde la toate întrebările dumneavoastră despre Adam Matériaux și pentru a vă ajuta să navigați pe site-ul nostru.",
    discover: "Puneți întrebările dumneavoastră...",
    search: "Caut în sursele mele",
  },
  ru: {
    loading_steps: {
      understanding: "Анализ запроса...",
      generating: "Генерация ответа...",
      verifying: "Финальная проверка...",
    },
    user: "Вы",
    assistant: "Супер Адам",
    agree: "Согласен",
    disagree: "Не согласен",
    agreed: "Вы согласились.",
    disagreed: "Вы не согласились.",
    welcome: "Здравствуйте, я Супер Адам, AI ассистент компании Adam Matériaux!",
    askQuestions:
      "Я здесь, чтобы ответить на все ваши вопросы о Adam Matériaux и помочь вам ориентироваться на нашем сайте.",
    discover: "Задавайте ваши вопросы...",
    search: "Ищу в моих источниках",
  },
  sr: {
    loading_steps: {
      understanding: "Анализирање захтева...",
      generating: "Генерисање одговора...",
      verifying: "Завршна провера...",
    },
    user: "Ви",
    assistant: "Супер Адам",
    agree: "Слажем се",
    disagree: "Не слажем се",
    agreed: "Сложили сте се.",
    disagreed: "Нисте се сложили.",
    welcome: "Здраво, ја сам Супер Адам, AI асистент компаније Adam Matériaux!",
    askQuestions:
      "Ту сам да одговорим на сва ваша питања о Adam Matériaux и да вам помогнем у коришћењу нашег сајта.",
    discover: "Поставите своја питања...",
    search: "Претражујем своје изворе",
  },
  sk: {
    loading_steps: {
      understanding: "Analýza požiadavky...",
      generating: "Generovanie odpovede...",
      verifying: "Posledná kontrola...",
    },
    user: "Vy",
    assistant: "Super Adam",
    agree: "Súhlasím",
    disagree: "Nesúhlasím",
    agreed: "Súhlasili ste.",
    disagreed: "Nesúhlasili ste.",
    welcome: "Ahoj, som Super Adam, AI asistent spoločnosti Adam Matériaux!",
    askQuestions:
      "Som tu, aby som odpovedal na všetky vaše otázky týkajúce sa Adam Matériaux a pomohol vám navigovať na našej stránke.",
    discover: "Kladite svoje otázky...",
    search: "Hľadám vo svojich zdrojoch",
  },
  sl: {
    loading_steps: {
      understanding: "Analiziranje zahteve...",
      generating: "Generiranje odgovora...",
      verifying: "Zadnja preverba...",
    },
    user: "Vi",
    assistant: "Super Adam",
    agree: "Strinjam se",
    disagree: "Ne strinjam se",
    agreed: "Strinjali ste se.",
    disagreed: "Niste se strinjali.",
    welcome: "Pozdravljeni, sem Super Adam, AI pomočnik Adam Matériaux!",
    askQuestions:
      "Tukaj sem, da odgovorim na vsa vaša vprašanja o Adam Matériaux in vam pomagam navigirati po naši spletni strani.",
    discover: "Postavite svoja vprašanja...",
    search: "Iščem v svojih virih",
  },
  cs: {
    loading_steps: {
      understanding: "Analýza požadavku...",
      generating: "Generování odpovědi...",
      verifying: "Finální kontrola...",
    },
    user: "Vy",
    assistant: "Super Adam",
    agree: "Souhlasím",
    disagree: "Nesouhlasím",
    agreed: "Souhlasili jste.",
    disagreed: "Nesouhlasili jste.",
    welcome: "Ahoj, jsem Super Adam, AI asistent společnosti Adam Matériaux!",
    askQuestions:
      "Jsem zde, abych odpověděl na všechny vaše otázky týkající se Adam Matériaux a pomohl vám se orientovat na našem webu.",
    discover: "Kladejte své dotazy...",
    search: "Hledám ve svých zdrojích",
  },
  tr: {
    loading_steps: {
      understanding: "İsteği analiz ediyorum...",
      generating: "Yanıt üretiyorum...",
      verifying: "Son kontrol...",
    },
    user: "Siz",
    assistant: "Süper Adam",
    agree: "Katılıyorum",
    disagree: "Katılmıyorum",
    agreed: "Katıldınız.",
    disagreed: "Katılmadınız.",
    welcome: "Merhaba, ben Adam Matériaux'nin AI yardımcısı Süper Adam'ım!",
    askQuestions:
      "Adam Matériaux ile ilgili tüm sorularınıza yanıt vermeye ve sitemizde gezinmenize yardımcı olmaya buradayım.",
    discover: "Sorularınızı sorun...",
    search: "Kaynaklarımı arıyorum",
  },
  uk: {
    loading_steps: {
      understanding: "Аналіз запиту...",
      generating: "Генерація відповіді...",
      verifying: "Остання перевірка...",
    },
    user: "Ви",
    assistant: "Супер Адам",
    agree: "Згоден",
    disagree: "Не згоден",
    agreed: "Ви погодились.",
    disagreed: "Ви не погодились.",
    welcome: "Привіт, я Супер Адам, AI асистент Adam Matériaux!",
    askQuestions:
      "Я тут, щоб відповісти на всі ваші питання про Adam Matériaux та допомогти вам орієнтуватися на нашому сайті.",
    discover: "Задавайте свої питання...",
    search: "Шукаю в моїх джерелах",
  },  
};
