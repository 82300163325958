// ChatBox.js

import React, { useRef, useEffect, useState } from "react";
import Loader from "./Loader";
import ChatMessage from "./ChatMessage";
import Welcome from "./Welcome";
const ChatBox = ({
  messages,
  audioOn,
  selectedLanguage,
  onDataFromChild,
  handleIsWriting,
  loading,
  history,
}) => {
  const chatBoxRef = useRef(null);
  const [currentLoadingStep, setCurrentLoadingStep] = useState(0);

  const getLastUserMessage = (messages, currentIndex) => {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (messages[i].isUser) {
        return messages[i];
      }
    }
    return null; // Aucun message d'utilisateur précédent
  };
  useEffect(() => {
    // Faites défiler vers le bas lorsque de nouveaux messages arrivent
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    // Mettez à jour l'état pour afficher Welcome après un délai
  }, [messages, handleIsWriting, currentLoadingStep]);

  // Réinitialiser l'état de l'étape de chargement actuelle lorsque le chargement est terminé
  useEffect(() => {
    setCurrentLoadingStep(0);
  }, [loading]);

  return (
    <div className="overflow-y-auto  h-full md:px-12 mt-6" ref={chatBoxRef}>
      {messages.length === 0 && <Welcome language={selectedLanguage} />}
      {messages.map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          isLastMessage={index === messages.length - 1}
          lastUserMessage={getLastUserMessage(messages, index)}
          audioOn={audioOn}
          selectedLanguage={selectedLanguage}
          onDataFromChild={onDataFromChild}
          handleIsWriting={handleIsWriting}
          loader={loading}
        />
      ))}
      {loading && (
        <Loader
          language={selectedLanguage}
          currentLoadingStep={currentLoadingStep}
          setCurrentLoadingStep={setCurrentLoadingStep}
        />
      )}
    </div>
  );
};

export default ChatBox;
