import { useEffect, useRef } from "react";
import AiIcon from "./AiIcon";
import GifWrapper from "./GifWrapper";
import Skeleton from "./Skeleton";

import { translations } from "../translations";
const LOADING_STEPS = ["understanding", "generating", "verifying"];

const Loader = ({ language, currentLoadingStep, setCurrentLoadingStep }) => {
  const timerRef = useRef(null);

  const loadingComponent = (step) => {
    if (step > LOADING_STEPS.length - 1) {
      return <Skeleton />;
    } else {
      return <GifWrapper step={step} />;
    }
  };

  useEffect(() => {
    if (currentLoadingStep >= LOADING_STEPS.length) {
      return clearInterval(timerRef.current);
    }

    const interval = setInterval(() => {
      setCurrentLoadingStep((prev) => {
        return prev + 1;
      });
    }, 1350); // the length of the gif seems to be around 1.35s

    timerRef.current = interval;
    return () => clearInterval(timerRef.current);
  }, [currentLoadingStep]);

  return (
    <div className="text-left mx-auto w-11/12 border-t-2 border-[#D0D3DB]">
      <div className={`mx-2 my-2 border-[#D0D3DB] relative w-full`}>
        <AiIcon />
        <div className="flex-col py-2 pl-6 mb-5 w-full ">
          <div className="flex w-full">
            <span className="text-base font-bold block align-middle whitespace-nowrap">
              {translations[language].assistant}
            </span>
          </div>
          <div className="flex items-center gap-1">
            {translations[language].loading_steps[LOADING_STEPS[currentLoadingStep]]}
            {loadingComponent(currentLoadingStep)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
