const ProductCard = ({ product }) => {
  return (
    <a
      href={product.url}
      onClick={(e) => {
        e.preventDefault();
        if (window.parent === window)
          // open in new tab
          window.open(product.url, "_blank");
        else window.parent.location.href = product.url; // Open the link in the parent window
      }}
      className="rounded-lg bg-transparent transition-colors hover:bg-gray-200 min-w-fit"
    >
      <div className="flex flex-col items-center gap-1 p-2">
        <img
          src={product.image}
          alt={product.name}
          width={250}
          height={"auto"}
          className="rounded-md object-cover"
        />
        <div className=" flex flex-col w-full  text-wrap max-w-60 text-left items-start">
          <h3 className="text-base">{product.name}</h3>
          {product.price && (
            <h4 className="text-base font-bold text-wrap">{parseInt(product.price).toFixed(2)}€</h4>
          )}
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
