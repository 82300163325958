const UserIcon = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 114 114"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-1 my-2 absolute top-0 -left-5 fill-primary"
    >
      <g clipPath="url(#clip0_2222_248)">
        <path d="M56.9348 0.625977C25.8355 0.625977 0.625 25.8365 0.625 56.9357C0.625 88.035 25.8355 113.246 56.9348 113.246C88.034 113.246 113.245 88.035 113.245 56.9357C113.245 25.8365 88.034 0.625977 56.9348 0.625977ZM56.9348 28.164C68.0703 28.164 77.1312 37.2248 77.1312 48.3604C77.1312 59.4972 68.0703 68.5568 56.9348 68.5568C45.7979 68.5568 36.7383 59.4972 36.7383 48.3604C36.7383 37.2248 45.7979 28.164 56.9348 28.164ZM56.9348 105.541C43.0012 105.541 30.503 99.4283 21.9514 89.7456C25.9269 82.5504 32.1422 76.6367 39.5325 73.0516C42.0465 71.8328 44.9808 71.8528 47.5848 73.1029C50.5167 74.5119 53.6625 75.2252 56.936 75.2252C60.2082 75.2252 63.3553 74.5106 66.2872 73.1029C68.8937 71.8516 71.8281 71.8328 74.3395 73.0516C81.7298 76.6354 87.9439 82.5504 91.9194 89.7456C83.3666 99.4271 70.8683 105.541 56.9348 105.541Z" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2222_248"
          x1="22.2644"
          y1="8.01522"
          x2="97.2996"
          y2="106.207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BC1119" />
          <stop offset="1" stopColor="#BB383E" />
        </linearGradient>
        <clipPath id="clip0_2222_248">
          <rect width="114" height="114" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
