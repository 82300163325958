// ChatModal.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import ChatInput from "./ChatInput";
import ChatBox from "./ChatBox";
import ChatSuggestions from "./ChatSuggestions";
import ChatHeader from "./ChatHeader";
import { getChatID } from "./chatIDModule";
import Disclaimer from "./Disclaimer";
import Cookies from "js-cookie";

const ChatModal = () => {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [suggestions, setSuggestion] = useState([
    "Pourquoi devrais-je voter pour vous ?",
    "Que comptez-vous faire pour les étudiants ?",
    "Quelles sont vos stratégies pour créer des emplois ?",
    "Avez-vous des projets de réforme pour les écoles ?",
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const [isAudioOn, setIsAudioOn] = useState(true);
  const [history, setHistory] = useState([]);
  const [isWriting, setIsWriting] = useState(false);
  const [generatedID, setGeneratedID] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [chunks, setChunks] = useState("");
  const [productId, setProductId] = useState(undefined);

  // Get the product ID from the URL query string
  useEffect(() => {
    const params = window.location.href;
    setProductId(params);
  }, []);

  const handleDataFromChild = (data) => {
    // La fonction de rappel pour remonter les données du composant enfant

    setChunks(data);
  };
  const handleLanguageChange = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage);
    // console.log((`LANGUEtestla: ${selectedLanguage}`));
  };

  const handleAudioChange = (audioOn) => {
    setIsAudioOn(audioOn);
  };

  const addMessage = async (text, isUser = true, isSuggestion) => {
    if (text !== "") {
      setMessages((prevMessages) => [...prevMessages, { text, isUser, isSuggestion }]);
      setIsWriting(true);
      // console.log("TEXT : "+text+"TEXX")
      if (
        isSuggestion &&
        text !== "Dis m'en plus..." &&
        text !== "Tell me more..." &&
        text !== "Vertel me meer..." &&
        text !== "Erzähl mir mehr..."
      ) {
        sendsuggestion(text);
        setUserMessage(text);
      } else if (isUser) {
        // Envoie le message de l'utilisateur à l'API

        sendUserMessageToAPI(text, generatedID, selectedLanguage, messages);
        setUserMessage(text);
      }
    }
  };
  const fecthHistoriqueMessage = async () => {
    try {
      // console.log(`CHATID FETCH HISTORY: ${generatedID}`);
      let chatID = Cookies.get("ChatID");
      if (!chatID) {
        chatID = getChatID();
        if (!chatID) {
          console.error("ChatID non trouvé");
          return;
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${chatID}`,
        },
        withCredentials: true,
      };

      const requestBody = {
        auth_key: "Q)[[ba%kOSb4$Dsep=hW#^epgqTmC_RFMqRDcd1=pHGbHk1d~d",
      };
      const response = await axios.post(
        "https://api.meridiem.be/AdamMat-GetSend-chats/get",
        requestBody,
        config,
      );

      const data = response.data;

      // Adaptation des messages
      const adaptedMessages = data.Messages.map((message) => [
        { text: message.User_Message, isUser: true },
        { text: message.AI_Message, isUser: false },
      ]);
      setHistory(adaptedMessages);
      setMessages([...messages, ...adaptedMessages.flat()]);
    } catch (error) {
      console.error("Erreur lors de la requête API :", error);
      // Gérer les erreurs d'API
    }
  };
  const [loader, setloader] = useState(false);
  const sendUserMessageToAPI = async (text, generatedID, selectedLanguage, messages) => {
    try {
      let chatID = Cookies.get("ChatID");
      if (!chatID) {
        chatID = getChatID();
        if (!chatID) {
          console.error("ChatID non trouvé");
          return;
        }
      }
      const userMessages = messages.filter((msg) => msg.isUser).reverse();
      const AIMessages = messages.filter((msg) => !msg.isUser).reverse();

      const lastUserMessage = userMessages[0] || {};
      const secondLastUserMessage = userMessages[1] || {};
      const lastUserMessageText = lastUserMessage.text || "";
      const secondLastUserMessageText = secondLastUserMessage.text || "";

      const lastAIMessage = AIMessages[0] || {};
      const secondLastAIMessage = AIMessages[1] || {};
      const lastAIMessageText = lastAIMessage.text || "";
      const secondLastAIMessageText = secondLastAIMessage.text || "";
      setloader(true);

      const response = await fetch("https://adam-mat-main.azurewebsites.net/chain", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${chatID}`,
        },
        // credentials: "include",
        body: JSON.stringify({
          query: text,
          chat_history: {
            human_last_msg: lastUserMessageText,
            ai_last_msg: lastAIMessageText,
            human_last_msg1: secondLastUserMessageText,
            ai_last_msg1: secondLastAIMessageText,
        },
          prod_ID: productId ?? "doctest", //@TODO: Replace with the actual product ID
          lang: selectedLanguage,
          chatID: Cookies.get("ChatID")
        }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! Statut: ${response.status}`);
      }

      setMessages((prevMessages) => [...prevMessages, { text: response, isUser: false }]);
      setloader(false);
    } catch (error) {
      console.error("Erreur lors de la requête API :", error);
      // Gérer les erreurs d'API
    }
  };

  const sendsuggestion = async (suggestion) => {
    try {
      setloader(true);
      let chatID = Cookies.get("ChatID");
      let bearer = `Bearer ${chatID}`;
      if (!chatID) {
        chatID = getChatID();
        bearer = `Bearer ${chatID}`;
        if (!chatID) {
          console.error("ChatID non trouvé");
          return;
        }
      }
      const response = await fetch("https://api.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        credentials: "include",
        body: JSON.stringify({
          input: { suggestion: suggestion },
        }),
      });

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: response, isUser: false, isSuggestion: true },
      ]);

      setloader(false);
    } catch (error) {
      console.error("Erreur lors de la requête API pour l'historique :", error);
      // Gérer les erreurs d'API pour l'historique
    }
  };

  const sendUserMessageAndAIResponseToHistory = async (userMessage, aiResponse) => {
    if (userMessage.trim() !== "") {
      try {
        let chatID = Cookies.get("ChatID");
        if (!chatID) {
          chatID = getChatID();
          if (!chatID) {
            console.error("ChatID non trouvé");
            return;
          }
        }

        // Création de l'objet de configuration pour inclure les headers
        const config = {
          headers: {
            Authorization: `Bearer ${chatID}`, // Ajouter le token d'authentification dans l'en-tête
            "Content-Type": "application/json", // Définir le Content-Type si nécessaire
          },
          withCredentials: true,
        };

        // Corps de la requête
        const requestBody = {
          auth_key: "Q)[[ba%kOSb4$Dsep=hW#^epgqTmC_RFMqRDcd1=pHGbHk1d~d",
          User_message: userMessage,
          AI_message: aiResponse,
        };
        // console.log("MESSAGE SENT TO HISTORY\n","user:",userMessage,"\nAI :",aiResponse);
        // Effectuer la requête POST avec Axios en passant le requestBody et config
        const response = await axios.post(
          "https://api.meridiem.be/AdamMat-GetSend-chats/send",
          requestBody,
          config,
        );

        // console.log(response);
      } catch (error) {
        console.error("Erreur lors de la requête API pour l'historique :", error);
      }
    }
  };

  const fetchSuggestions = async (userMessage, IAmessage, selectedLanguage) => {
    try {
      // console.log((`LANGUESUGG: ${selectedLanguage}`));
      let chatID = Cookies.get("ChatID");
      if (!chatID) {
        chatID = getChatID();
        if (!chatID) {
          console.error("ChatID non trouvé");
          return;
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${chatID}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(
        "https://api.com",
        {
          lang: selectedLanguage,
          userMessage: userMessage,
        },
        config,
      );
      // console.log(response);
      setSuggestion(response.data);

      // Assurez-vous que la structure de la réponse est correcte
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions :", error);
      // Gérer les erreurs d'API
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      console.log('Effect running', { chunks, userMessage, isWriting });
      if (!isWriting && chunks !== "") { //!isWriting && chunks !== ""
        console.log('Sending Message');
        // console.log('USER MESSAGE1:',userMessage);
        // console.log('CHUNKS1:',chunks);
        sendUserMessageAndAIResponseToHistory(userMessage, chunks);
        fetchSuggestions(userMessage, chunks, selectedLanguage);
        setChunks(""); // Clear chunks after sending
      }
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chunks, userMessage]);

  const handleSuggestionClick = (suggestion) => {
    if (!isWriting) {
      addMessage(suggestion, true, true);
    }
  };
  useEffect(() => {
    // Vérifiez si le cookie 'ChatID' existe
    const existingChatID = Cookies.get("ChatID");
    const existingChatIDnocookie = getChatID();
    // const { chatID_back } = setChatID();
    // console.log(chatID_back) || chatID_back
    if (existingChatID) {
      fecthHistoriqueMessage();
      // Si le cookie existe, masquez le composant Disclaimer et définissez l'ID de la conversation
      setShowDisclaimer(false);
      setGeneratedID(existingChatID);
    } else {
      if (existingChatIDnocookie) {
        setShowDisclaimer(false);
        setGeneratedID(existingChatID);
      } else {
        // Si le cookie n'existe pas, affichez le composant Disclaimer
        setShowDisclaimer(true);
        setMessages([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisclaimer, generatedID]);

  const handleIDGeneration = (id) => {
    setGeneratedID(id);
    setShowDisclaimer(false);
  };
  const handleDeleteChatID = (deletechatID) => {
    setShowDisclaimer(deletechatID);
    setMessages([]);
    setHistory([]);
    setSuggestion([
      "Pourquoi devrais-je voter pour vous ?",
      "Que comptez-vous faire pour les étudiants ?",
      "Quelles sont vos stratégies pour créer des emplois ?",
      "Avez-vous des projets de réforme pour les écoles ?",
    ]);
  };
  const handleIsWriting = (finishMessage) => {
    setIsWriting(finishMessage);
    // console.log(finishMessage);
  };

  return (
    <div className={` fixed top-0 left-0 w-full h-full flex items-center bg-black bg-opacity-40`}>
      <Disclaimer onIDGenerated={handleIDGeneration} show={showDisclaimer} />

      <div className={`w-full sm:h-full  h-full flex  ${!showDisclaimer ? "" : " blur-sm"}  `}>
        <div className="relative bg-background   w-full  h-full border-[1px] border-[#A8A8A8] shadow-2xl flex flex-col  text-center ">
          <ChatHeader
            onLanguageChange={handleLanguageChange}
            onAudioClick={handleAudioChange}
            chatId={generatedID}
            deletechatID={handleDeleteChatID}
          />
          <ChatBox
            messages={messages}
            audioOn={isAudioOn}
            selectedLanguage={selectedLanguage}
            onDataFromChild={handleDataFromChild}
            handleIsWriting={handleIsWriting}
            loading={loader}
            history={history}
          />
          {!isWriting && (
            <ChatSuggestions onSuggestionClick={handleSuggestionClick} suggestions={suggestions} />
          )}
          <ChatInput
            addMessage={addMessage}
            disabled={isWriting}
            selectedLanguage={selectedLanguage}
          />
          <div className="flex items-center w-10/12 justify-between mx-auto my-2">
            <a
              className="flex items-center text-xs mx-2 "
              href="https://www.meridiem.be/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex-shrink-0 bg-[#1B163C] rounded-md p-1">
                <img
                  src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/LOGO_32.png"
                  alt=""
                  className="w-6 h-6"
                />
              </div>
              <span className="flex-shrink-0 ml-2">powered by</span>
              <span className="underline ml-1 flex-shrink-0">Meridiem</span>
            </a>
            <span className="text-xs mx-2"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;
