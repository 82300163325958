import ProductCard from "./ProductCard";

const CardContainer = ({ products }) => {
  return (
    <section className="w-full h-auto py-4 flex">
      <div className="container flex gap-1 min-h-fit overflow-x-scroll pb-1">
        {products.map((product) => (
          <ProductCard key={product.name} product={product} />
        ))}
      </div>
    </section>
  );
};

export default CardContainer;
