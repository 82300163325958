const AiIcon = () => {
  return (
    <img
      src="/ai_icon.png"
      alt=""
      className="mx-1 my-2 absolute top-0 -left-5"
      style={{ width: "30px", height: "30px" }}
    />
  );
};

export default AiIcon;
