import { useState, useEffect, useLayoutEffect } from "react";
import ContentLoader from "react-content-loader";

const Skeleton = () => {
  const [rectHeight, setRectHeight] = useState(20);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setRectHeight(20);
    }
    if (width >= 768) {
      setRectHeight(10);
    }
  };

  useLayoutEffect(() => handleResize(), []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-full pt-2">
      <ContentLoader
        speed={1.2}
        width={"100%"}
        viewBox="0 0 800 80"
        backgroundColor="#c9c9c9"
        foregroundColor="white"
      >
        <rect x="0" y="0" rx="3" ry="3" width="800" height={rectHeight} />
        <rect x="0" y={rectHeight * 1.5} rx="3" ry="3" width="800" height={rectHeight} />
        <rect x="0" y={rectHeight * 3} rx="3" ry="3" width="600" height={rectHeight} />
      </ContentLoader>
    </div>
  );
};

export default Skeleton;
