import React, { useState, useEffect } from "react";

const GifWrapper = ({ step }) => {
  const [src, setSrc] = useState(" ");

  // This is a workaround to make the gif reload on every step change
  useEffect(() => {
    setSrc(" ");
    const time = setTimeout(() => {
      setSrc("././checked_animation.gif");
    }, 0);
    return () => {
      clearTimeout(time);
    };
  }, [step]);

  return <img src={src} alt="" className="  w-5 h-5 inline-block " />;
};

export default GifWrapper;
